import "./Header.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { HeaderContext } from "../header-context/HeaderContext";
import DarkMode from "../darkmode/DarkMode";
import logo from "../../assets/images/logo.png";

export default function Header() {
  const {
    about,
    games,
    apps,
    contact,

    scrollToSection,
  } = useContext(HeaderContext);

  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg ">
        <div className="container-fluid">
          <Link to="/" className="logo">
            <img
              src={logo}
              style={{ height: "66px", width: "66px" }}
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav margin-auto mb-2 mb-lg-0">
              <>
                <li className="nav-item">
                  <a onClick={() => scrollToSection(about)}>About</a>
                </li>
                <li className="nav-item">
                  <a onClick={() => scrollToSection(games)}>Games</a>
                </li>
                <li className="nav-item">
                  <a onClick={() => scrollToSection(apps)}>Applications</a>
                </li>
                <li className="nav-item">
                  <a onClick={() => scrollToSection(contact)}>Contact</a>
                </li>
              </>
            </ul>

            <ul className="navbar-nav  mb-2 mb-lg-0">
              <li className="nav-item">
                <DarkMode />
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
