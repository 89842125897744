import { useRef } from "react";
import { createContext } from "react";

export const HeaderContext = createContext({});

export function HeaderContextProvider({ children }) {
  const about = useRef(null);
  const games = useRef(null);
  const apps = useRef(null);
  const contact = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <HeaderContext.Provider
      value={{
        scrollToSection,
        about,
        games,
        apps,
        contact,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}
