import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import HomePage from "./pages/home-page/HomePage";
import ErrorPage from "./pages/error-page/ErrorPage";
import { HeaderContextProvider } from "./components/header-context/HeaderContext";

function App() {
  return (
    <HeaderContextProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </HeaderContextProvider>
  );
}

export default App;
